#home-banner {
  display: flex;
  flex-wrap: wrap;
  margin-top: 27px;

  .big-block {
    flex-basis: 50%;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      flex-basis: 100%;
    } // end of @media (max-width: 767px)

    &#big-block-left {
      background-image: url(../images/backgrounds/order.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 40%;

      .small-block {
        width: 50%;
        background-color: rgba(255,255,255,0.6);

        @media (max-width: 767px) {
          flex-basis: 100%;
        } // end of @media (max-width: 767px)

      } // end of .small-block

      .inner-block {
        padding: 50px 10px;
        box-shadow: 0px -1px 20px 0px rgba(0, 0, 0, 0.5);

        .inner-block-content {
          width: 100%;
          height: 100%;          

          h2 {
            text-align: center;
            color: #570c71;
          } // end of h2

          a {
            text-decoration: none;
          }

        } // end of .inner-block-content

        &.block-latest-news {
          .inner-block-content {            

            .news-list {
              height: 100%;
              overflow-y: scroll;
              li {
                font-size: 14px;
                margin-top: 10px;
                &, a {
                  color: rgb(33, 37, 41);
                  font-weight: 700;
                }
              } // end of li
            } // end of .news-list

          } // end of .inner-block-content
        } // end of &.block-latest-news

      } // end of .inner-block

    } // end of &#big-block-left
    
    .inner-block {      
      height: 368px;
      // border: 1px solid #999;

      @media (max-width: 767px) {
        height: 200px;
      } // end of @media (max-width: 767px)
    } // end of .small-block

    .block-order-supplies {
      width: 100%;
      padding: 0 !important;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;      
    }

    &#big-block-right {
      .small-block {
        justify-content: center;
        align-items: flex-end;
      } // end of .small-block
    } // end of &#big-block-right

    .small-block {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      

      &.block-member-info {
        background-image: url(../images/backgrounds/member-information.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 40%;
      } // end of &.block-member-info

      &.block-useful-forms {
        background-image: url(../images/backgrounds/volunteer.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      } // end of &.block-usefule-forms

      &.block-useful-links {
        background-image: url(../images/backgrounds/running.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      } // end of &.block-useful-links

      &.block-contact-us {
        background-image: url(../images/backgrounds/contact-us.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      } // end of &.block-contact-us

    } // end of .small-block

    a.banner-btn {
      width: 256px;
      text-decoration: none;
      background-color: rgba(88,12,113,0.7);
      border: 2px solid rgba(145,62,173,0.7);
      border-radius: 5px;
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      letter-spacing: 1px;
      padding: 12px 0;
      margin-bottom: 50px;
      transition: 0.1s background-color ease-in-out;

      &:hover {
        background-color: rgba(153,0,153,1.0);
      }

      @media (max-width: 767px) {
        font-size: 12px;
        width: 80%;
      } // end of @media (max-width: 767px)

    } // end of a.banner-btn

  } // end of .big-block

} // end of .home-banner